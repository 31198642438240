.user-list-item {
    position: relative;
    padding-right: 50px; /* Adjust padding to ensure text doesn't overlap with the button */
  }
  
  .remove-button {
    position: absolute;
    right: 10px; /* Adjust the position as needed */
    top: 50%;
    transform: translateY(-50%);
  }