.tab-content{
    width: 100%;
    height: calc(100vh - 137px);
    background-color: rgb(92, 92, 92);
    overflow: auto;
}
.tab-content>.active {
    width: 100%;
}

.nav {
    background-color: #262323 !important;
}
.nav-item > .nav-link {
    color: #cecccc
}

.nav-item > .nav-link:hover {
    color: #a3de00 !important;
    border-color: #e9ecef #e9ecef #dee2e6 !important;
}


.my-tabs > .nav-item > .nav-tabs .nav-link, .nav-tabs .nav-link.active {
    color: #a3de00 !important;
    background-color: #8a888800 !important;
    border-color: rgb(92, 92, 92) rgb(92, 92, 92) rgb(92, 92, 92) !important;
}
.my-tabs .nav .nav-tabs {
    display: flex;
    flex-direction: column !important;  
}

