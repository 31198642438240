@import url('https://fonts.googleapis.com/css?family=Open+Sans:600|Work+Sans:400,500,600,700&display=swap');

#loginform{
  max-width: 500px;
  min-width: 300px;
  max-height: 700px;
  width: 30%;
  height: 60%;
  position: absolute;
  top: 20%;
  right: 35%;
  background-color:#ffffff;
  border-radius: 25px;
}

#headerTitle{
  text-align: center;
  font-family: 'open sans', sans-serif;
  padding: 2rem 0;
  margin: 0;
  font-size: 2rem;
}

.login-row{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  max-width: 100%;
  margin-left: 0 !important;
  
}

.login-row input{
  width: 80%;
  box-sizing: border-box;
	border: none;
  font-size: 1.3rem;
  padding-left: 1.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  box-shadow: inset 0px -3px 0px 0px rgba(187,187,187,0.2);
  transition: box-shadow 0.2s ease-in;
}

.login-row input:focus{
   box-shadow: inset 0px -3px 0px 0px rgba(34,193,195,0.7);
   outline: none;
}

.login-row input::-webkit-input-placeholder{
  opacity: 1;
  transition: opacity 0.25s ease-out;
}

.login-row input:hover::-webkit-input-placeholder,
.login-row input:focus::-webkit-input-placeholder{
  opacity: 0;
}

.login-row label{
  align-self: start;
  padding-left: 4.5rem;
  padding-bottom: 0.5rem;
  color: rgba(187,187,187,0.9);
}

.login-row button{
  border-radius: 25px;
  width: 80%;
  height: 40px;
  font-size: 1.3rem;
  color: white;
  font-weight: 700;
  background: rgb(34,193,195);
  background: linear-gradient(
    90deg,#67b486, #65a747,#A2C520);
  border: 0px;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
}
.df-button{
  border-radius: 25px;
  font-size: 1.3rem;
  color: white;
  font-weight: 700;
  background: rgb(34,193,195);
  background: linear-gradient(
    90deg,#67b486, #65a747,#A2C520);
  border: 0px;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
  margin-top: 0.5em;
}

.login-row button:hover{
  opacity: 0.8;
}

#button{
  padding-bottom: 1.5rem;
}

#iconGroup{
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

#iconGroup a{
  margin: 0 0.6rem;
}

.loginFormWrapper {
    width: 100%;
}

.loginWrapper {
    display: flex;
    height: 100%;
    background-image: url('./df_background.png') !important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    /* conic-gradient(from 300deg, rgb(64, 126, 90), #758f18, #375e28, rgb(64, 126, 90)) !important; */
}